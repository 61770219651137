import { createSelector } from 'reselect';
import { buildArchiveLinks } from '../services/archive-links';
import { getPostStatsByDate } from '../../common/store/post-stats-by-date/post-stats-by-date-selectors';
import { getMonthsDisplayLimit, getShowNewestPostsFirst } from './archive-selectors';

export const getArchiveLinks = createSelector(
  getPostStatsByDate,
  getShowNewestPostsFirst,
  getMonthsDisplayLimit,
  (postStatsByDate, showNewestPostsFirst, monthsDisplayLimit) =>
    buildArchiveLinks(postStatsByDate, showNewestPostsFirst, monthsDisplayLimit),
);
